export default [
    {
        path: '/fislistesi',
        name: 'fislistesi',
        component: () => import('@/views/depo/fisListesi.vue'),
        meta: {
            pageTitle: 'Depolar',
            icon: 'StarIcon',
            breadcrumb: [
                {
                    first: 'Depolar',
                    second: 'Fiş Listesi',
                    active: true,
                },
            ],
        },
    },
    {
        path: '/fisDetay/:id',
        name: 'fisDetay',
        component: () => import('@/views/depo/fisDetay.vue'),
        meta: {
            pageTitle: 'Fiş Listesi',
            icon: 'StarIcon',
            breadcrumb: [
                {
                    first: 'Fiş Listesi',
                    second: 'Fiş Detay',
                    active: true,
                },
            ],
        },
    },
    {
        path: '/yeniFis',
        name: 'yeniFis',
        component: () => import('@/views/depo/yeniFis.vue'),
        meta: {
            pageTitle: 'Yeni Fiş',
            icon: 'StarIcon',
            breadcrumb: [
                {
                    first: 'Fiş Listesi',
                    second: 'Fiş Oluştur',
                    active: true,
                },
            ],
        },
    },
    {
        path: '/depolar',
        name: 'depolar',
        component: () => import('@/views/depo/depoList.vue'),
        meta: {
            pageTitle: 'Depolar',
            icon: 'StarIcon',
            breadcrumb: [
                {
                    first: 'Depolar',
                    second: 'Depo Listesi',
                    active: true,
                },
            ],
        },
    },
    {
        path: '/depoDetay/:id',
        name: 'depoDetay',
        component: () => import('@/views/depo/depoDetay.vue'),
        meta: {
            pageTitle: 'Depolar',
            icon: 'StarIcon',
            breadcrumb: [
                {
                    first: 'Depolar',
                    second: 'Depo Listesi',
                    secondLink: 'depolar',
                    third: 'Depo Detay',
                    active: true,
                },
            ],
        },
    },
    {
        path: '/aktarimDetay/:id',
        name: 'aktarimDetay',
        component: () => import('@/views/depo/aktarimDetay.vue'),
        meta: {
            pageTitle: 'Depolar',
            icon: 'StarIcon',
            breadcrumb: [
                {
                    first: 'Depolar',
                    second: 'Depo Listesi',
                    secondLink: 'depolar',
                    third: 'Depo Detay',
                    fourth: 'Aktarım Detay',
                    active: true,
                },
            ],
        },
    },
    {
        path: '/depoKomisyonUyeleri',
        name: 'depoKomisyonUyeleri',
        component: () => import('@/views/depo/depoKomisyonUyeleri.vue'),
        meta: {
            pageTitle: 'Depo Komisyon Üyeleri',
            icon: 'StarIcon',
            breadcrumb: [
                {
                    first: 'Depo',
                    second: 'Komisyon Üyeleri',
                    active: true,
                },
            ],
        },
    },
]
