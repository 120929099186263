import store from '@/store'

export default {
    state: {
        uyeler: [],
        komisyonSema: [],
        fisListesi: [],
        fisDetay: [],
        aktarimDetay: [],
        depoList: [],
        depoDetay: {
            depoStokList: [],
            depoAktarim: [],
            aktarimDetayFiltre: [],
        },
    },
    getters: {
        AKTARIM_DETAY_GET(state) {
            if (state.aktarimDetay) {
                return {
                    bilgiler: state.aktarimDetay.bilgiler,
                    aktarilanStoklar: state.aktarimDetay.aktarilanStoklar,
                }
            }
            return false
        },
        DEPO_DETAY(state) {
            if (state.depoDetay) {
                return {
                    depoStokList: state.depoDetay.depoStokList,
                    depoAktarim: state.depoDetay.depoAktarim,
                    aktarimDetayFiltre: state.depoDetay.aktarimDetayFiltre,
                }
            }
            return false
        },
        DEPO_LIST_GET(state) {
            if (state.depoList) {
                return state.depoList
            }
            return false
        },
        DEPO_UYELER_GET(state) {
            if (state.uyeler) {
                return state.uyeler
            }
            return false
        },
        DEPO_KOMISYON_SEMA_GET(state) {
            if (state.komisyonSema) {
                return state.komisyonSema
            }
            return false
        },
        FISLIST_GET(state) {
            if (state.fisListesi) {
                return state.fisListesi
            }
            return false
        },
        FIS_DETAY_GET(state) {
            if (state.fisDetay) {
                return {
                    siparisFisOzeti: state.fisDetay.siparisFisOzeti || '',
                    siparisFisBilgileri: state.fisDetay.siparisFisBilgileri || '',
                }
            }
            return false
        },
    },
    mutations: {
        DEPO_DETAY_AKTARIM_DETAY_ACTION(state, payload) {
            const veri = { ...payload }
            payload != 'remove' ? state.aktarimDetay = veri : state.aktarimDetay = []
        },
        DEPO_DETAY_AKTARIM_ACTION(state, payload) {
            payload != 'remove' ? state.depoDetay.depoAktarim = { ...payload } : state.depoDetay.depoAktarim = []
        },
        DEPO_DETAY_TARIH_FILTRE_ACTION(state, payload) {
            payload != 'remove' ? state.depoDetay.aktarimDetayFiltre = { ...payload } : state.depoDetay.aktarimDetayFiltre = []
        },
        DEPO_DETAY_STOK_ACTION(state, payload) {
            payload != 'remove' ? state.depoDetay.depoStokList = { ...payload } : state.depoDetay.depoStokList = []
        },
        DEPOLIST_ACTION(state, payload) {
            payload != 'remove' ? state.depoList = { ...payload } : state.depoList = []
        },
        DEPO_UYELER_ACTION(state, payload) {
            payload !== 'remove' ? state.uyeler = { ...payload } : state.uyeler = []
        },
        DEPO_KOMISYON_SEMA_ACTION(state, payload) {
            payload !== 'remove' ? state.komisyonSema = { ...payload } : state.komisyonSema = []
        },
        FISLIST_ACTION(state, payload) {
            payload != 'remove' ? state.fisListesi = { ...payload } : state.fisListesi = []
        },
        FIS_DETAY_ACTION(state, payload) {
            const veri = { ...payload }
            payload != 'remove' ? state.fisDetay = veri : state.fisDetay = []
        },
    },
    actions: {
        aktarimDetayIade({ commit }, authData) {
            const data = {
                crm_token: store.getters.TOKEN_GET,
                serviceName: 'depo',
                methodName: 'aktarimDetayIade',
                aktarimID: authData.aktarimID,
                aktarilanAdet: authData.aktarilanAdet || 0,
                detayID: authData.detayID,
            }
            return new Promise((resolve, reject) => {
                $.ajax({
                    type: 'POST',
                    url: store.state.POST_URL,
                    data,
                    success(res) {
                        commit('DEPO_DETAY_AKTARIM_DETAY_ACTION', res)
                        commit('setToken', res.userToken)
                        resolve(res)
                    },
                    error(err) {
                        reject(err)
                    },
                    dataType: 'json',
                })
            })
        },
        aktarimDetay({ commit }, authData) {
            const data = {
                crm_token: store.getters.TOKEN_GET,
                serviceName: 'depo',
                methodName: 'aktarimDetay',
                aktarimID: authData,
            }
            return new Promise((resolve, reject) => {
                $.ajax({
                    type: 'POST',
                    url: store.state.POST_URL,
                    data,
                    success(res) {
                        commit('DEPO_DETAY_AKTARIM_DETAY_ACTION', res)
                        commit('setToken', res.userToken)
                        resolve(res)
                    },
                    error(err) {
                        reject(err)
                    },
                    dataType: 'json',
                })
            })
        },
        depoDetayAktarimFiltre({ commit }, authData) {
            const data = {
                crm_token: store.getters.TOKEN_GET,
                serviceName: 'depo',
                methodName: 'aktarimDetayTarihFiltre',
                baslangic: authData.baslangic,
                bitis: authData.bitis,
                depoID: authData.depoID,
            }
            return new Promise((resolve, reject) => {
                $.ajax({
                    type: 'POST',
                    url: store.state.POST_URL,
                    data,
                    success(res) {
                        commit('DEPO_DETAY_TARIH_FILTRE_ACTION', res.aktarilanStoklar)
                        commit('setToken', res.userToken)
                        resolve(res)
                    },
                    error(err) {
                        reject(err)
                    },
                    dataType: 'json',
                })
            })
        },
        depoDetayAktarim({ commit }, authData) {
            const data = {
                crm_token: store.getters.TOKEN_GET,
                serviceName: 'depo',
                methodName: 'depoAktarimSearch',
                baslangic: authData.baslangic || 0,
                uzunluk: authData.uzunluk || 40,
                searchKey: authData.searchKey,
                depoID: authData.depoID,
            }
            return new Promise((resolve, reject) => {
                $.ajax({
                    type: 'POST',
                    url: store.state.POST_URL,
                    data,
                    success(res) {
                        commit('DEPO_DETAY_AKTARIM_ACTION', res.liste)
                        commit('setToken', res.userToken)
                        resolve(res)
                    },
                    error(err) {
                        reject(err)
                    },
                    dataType: 'json',
                })
            })
        },
        depoDetayStok({ commit }, authData) {
            const data = {
                crm_token: store.getters.TOKEN_GET,
                serviceName: 'depo',
                methodName: 'depoStokListesiSearch',
                baslangic: authData.baslangic || 0,
                uzunluk: authData.uzunluk || 40,
                searchKey: authData.searchKey,
                depoID: authData.depoID,
            }
            return new Promise((resolve, reject) => {
                $.ajax({
                    type: 'POST',
                    url: store.state.POST_URL,
                    data,
                    success(res) {
                        commit('DEPO_DETAY_STOK_ACTION', res.liste)
                        commit('setToken', res.userToken)
                        resolve(res)
                    },
                    error(err) {
                        reject(err)
                    },
                    dataType: 'json',
                })
            })
        },
        depoUpdate({ commit }, authData) {
            const data = {
                crm_token: store.getters.TOKEN_GET,
                serviceName: 'depo',
                methodName: 'depoDuzenle',
                depoAdi: authData.depoAdi,
                depoID: authData.depoID,
            }
            return new Promise((resolve, reject) => {
                $.ajax({
                    type: 'POST',
                    url: store.state.POST_URL,
                    data,
                    success(res) {
                        if (res.result.status == 200) {
                            commit('DEPOLIST_ACTION', res.data)
                            commit('setToken', res.userToken)
                            resolve(res)
                        }
                        reject(res)
                    },
                    error(err) {
                        reject(err)
                    },
                    dataType: 'json',
                })
            })
        },
        depoRemove({ commit }, authData) {
            const data = {
                crm_token: store.getters.TOKEN_GET,
                serviceName: 'depo',
                methodName: 'depoSil',
                depoID: authData,
            }
            return new Promise((resolve, reject) => {
                $.ajax({
                    type: 'POST',
                    url: store.state.POST_URL,
                    data,
                    success(res) {
                        if (res.result.status == 200) {
                            commit('DEPOLIST_ACTION', res.data)
                            commit('setToken', res.userToken)
                            resolve(res)
                        }
                        reject(res)
                    },
                    error(err) {
                        reject(err)
                    },
                    dataType: 'json',
                })
            })
        },
        depoSave({ commit }, authData) {
            const data = {
                crm_token: store.getters.TOKEN_GET,
                serviceName: 'depo',
                methodName: 'depoKaydet',
                depoAdi: authData,
            }
            return new Promise((resolve, reject) => {
                $.ajax({
                    type: 'POST',
                    url: store.state.POST_URL,
                    data,
                    success(res) {
                        if (res.result.status == 200) {
                            commit('DEPOLIST_ACTION', res.data)
                            commit('setToken', res.userToken)
                            resolve(res)
                        }
                        reject(res)
                    },
                    error(err) {
                        reject(err)
                    },
                    dataType: 'json',
                })
            })
        },
        depoListesi({ commit }) {
            const data = {
                crm_token: store.getters.TOKEN_GET,
                serviceName: 'depo',
                methodName: 'depoListesi',
            }
            return new Promise((resolve, reject) => {
                $.ajax({
                    type: 'POST',
                    url: store.state.POST_URL,
                    data,
                    success(res) {
                        const liste = { ...res }
                        delete liste.userToken
                        commit('DEPOLIST_ACTION', liste)
                        commit('setToken', res.userToken)
                        resolve(res)
                    },
                    error(err) {
                        reject(err)
                    },
                    dataType: 'json',
                })
            })
        },
        depoKomisyonUyeleri({ commit }) {
            const data = {
                crm_token: store.getters.TOKEN_GET,
                serviceName: 'depo',
                methodName: 'komisyonSemaListesi',
            }
            return new Promise((resolve, reject) => {
                $.ajax({
                    type: 'POST',
                    url: store.state.POST_URL,
                    data,
                    success(res) {
                        commit('DEPO_KOMISYON_UYELER_ACTION', res.liste)
                        commit('setToken', res.userToken)
                        resolve(res)
                    },
                    error(err) {
                        reject(err)
                    },
                    dataType: 'json',
                })
            })
        },
        depoKomisyonUyeDuzenle({ commit }, authData) {
            const data = {
                crm_token: store.getters.TOKEN_GET,
                serviceName: 'depo',
                methodName: 'komisyonUyeDuzenle',
                uyeID: authData.uyeID,
                adSoyad: authData.adSoyad,
                unvan: authData.unvan,
            }
            return new Promise((resolve, reject) => {
                $.ajax({
                    type: 'POST',
                    url: store.state.POST_URL,
                    data,
                    success(res) {
                        commit('setToken', res.userToken)
                        resolve(res)
                    },
                    error(err) {
                        reject(err)
                    },
                    dataType: 'json',
                })
            })
        },
        depoKomisyonUyeSil({ commit }, authData) {
            const data = {
                crm_token: store.getters.TOKEN_GET,
                serviceName: 'depo',
                methodName: 'komisyonUyeSil',
                uyeID: authData,
            }
            return new Promise((resolve, reject) => {
                $.ajax({
                    type: 'POST',
                    url: store.state.POST_URL,
                    data,
                    success(res) {
                        commit('setToken', res.userToken)
                        resolve(res)
                    },
                    error(err) {
                        reject(err)
                    },
                    dataType: 'json',
                })
            })
        },
        depoKomisyonUyeKaydet({ commit }, authData) {
            const data = {
                crm_token: store.getters.TOKEN_GET,
                serviceName: 'depo',
                methodName: 'komisyonUyeKaydet',
                adSoyad: authData.adSoyad,
                unvan: authData.unvan,
            }
            return new Promise((resolve, reject) => {
                $.ajax({
                    type: 'POST',
                    url: store.state.POST_URL,
                    data,
                    success(res) {
                        commit('setToken', res.userToken)
                        resolve(res)
                    },
                    error(err) {
                        reject(err)
                    },
                    dataType: 'json',
                })
            })
        },
        depoUyeListesi({ commit }) {
            const data = {
                crm_token: store.getters.TOKEN_GET,
                serviceName: 'depo',
                methodName: 'komisyonUyeListesi',
            }
            return new Promise((resolve, reject) => {
                $.ajax({
                    type: 'POST',
                    url: store.state.POST_URL,
                    data,
                    success(res) {
                        commit('setToken', res.userToken)
                        delete res.userToken
                        commit('DEPO_UYELER_ACTION', res)
                        resolve(res)
                    },
                    error(err) {
                        reject(err)
                    },
                    dataType: 'json',
                })
            })
        },
        depoKomisyonSemaKaydet({ commit }, authData) {
            const data = {
                crm_token: store.getters.TOKEN_GET,
                serviceName: 'depo',
                methodName: 'komisyonSemaKaydet',
                uyeID: authData.uyeID,
                pozisyon: authData.pozisyon,
                evrakTuru: authData.evrakTuru,
            }
            return new Promise((resolve, reject) => {
                $.ajax({
                    type: 'POST',
                    url: store.state.POST_URL,
                    data,
                    success(res) {
                        commit('setToken', res.userToken)
                        resolve(res)
                    },
                    error(err) {
                        reject(err)
                    },
                    dataType: 'json',
                })
            })
        },
        depoKomisyonSemaListesi({ commit }) {
            const data = {
                crm_token: store.getters.TOKEN_GET,
                serviceName: 'depo',
                methodName: 'komisyonSemaListesi',
            }
            return new Promise((resolve, reject) => {
                $.ajax({
                    type: 'POST',
                    url: store.state.POST_URL,
                    data,
                    success(res) {
                        commit('setToken', res.userToken)
                        delete res.userToken
                        commit('DEPO_KOMISYON_SEMA_ACTION', res)
                        resolve(res)
                    },
                    error(err) {
                        reject(err)
                    },
                    dataType: 'json',
                })
            })
        },
        depoKomisyonSemaSil({ commit }, authData) {
            const data = {
                crm_token: store.getters.TOKEN_GET,
                serviceName: 'depo',
                methodName: 'komisyonSemaSil',
                semaID: authData,
            }
            return new Promise((resolve, reject) => {
                $.ajax({
                    type: 'POST',
                    url: store.state.POST_URL,
                    data,
                    success(res) {
                        commit('setToken', res.userToken)
                        resolve(res)
                    },
                    error(err) {
                        reject(err)
                    },
                    dataType: 'json',
                })
            })
        },
        fisListesiSearch({ commit }, authData) {
            const data = {
                crm_token: store.getters.TOKEN_GET,
                serviceName: 'depo',
                methodName: 'siparisFisListesiSearch',
                baslangic: authData.baslangic || 0,
                uzunluk: authData.uzunluk || 40,
                searchKey: authData.searchKey,
            }
            return new Promise((resolve, reject) => {
                $.ajax({
                    type: 'POST',
                    url: store.state.POST_URL,
                    data,
                    success(res) {
                        commit('FISLIST_ACTION', res.data)
                        commit('setToken', res.userToken)
                        resolve(res)
                    },
                    error(err) {
                        reject(err)
                    },
                    dataType: 'json',
                })
            })
        },
        fisDetay({ commit }, authData) {
            const data = {
                crm_token: store.getters.TOKEN_GET,
                serviceName: 'depo',
                methodName: 'siparisFisDetay',
                fisID: authData,
            }
            return new Promise((resolve, reject) => {
                $.ajax({
                    type: 'POST',
                    url: store.state.POST_URL,
                    data,
                    success(res) {
                        commit('FIS_DETAY_ACTION', res)
                        commit('setToken', res.userToken)
                        resolve(res)
                    },
                    error(err) {
                        reject(err)
                    },
                    dataType: 'json',
                })
            })
        },
    },
}
